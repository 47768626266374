import axios from "axios";
import { setInterceptor } from "@/api/interceptor";

const apiFactory = function(axios) {
    return {
        getMembers: () => {
            return axios.get('/users');
        },
        getNewsList: () => {
            return axios.get('/news-list');
        },
        getNews: (id) => {
            return axios.get(`/news/${id}`);
        },
        getPartNewsList: (indexObj) => {
            return axios.post('/news-list', JSON.stringify(indexObj));
        },
        getProjectList: () => {
            // data from table 'projects'
            return axios.get('/project-list');
        },
        getPartProjectList: (indexObj) => {
            return axios.post('/project-list', JSON.stringify(indexObj));
        },
        getProject: (id) => {
            // data from table 'projects'
            return axios.get(`/project/${id}`);
        },
        getIMProjectList: () => {
            // data from table 'imProject'
            return axios.get('/improject-list');
        },
        getIMProject: (id) => {
            // data from table 'imProject'
            return axios.get(`/improject/${id}`);
        },
        getPartIMProjectList: (indexObj) => {
            return axios.post('/improject-list', JSON.stringify(indexObj));
        },
        getProjectCategories: () => {
            return axios.get('/category');
        },
        getPublicationList:() => {
            return axios.get('/publication-list');
        },
        getPdfFile:(folderName, fileName) => {
            return axios.post('/file/url', JSON.stringify({foldername:folderName, filename:fileName + '.pdf'}));
        },
    }
};

const axiosInstance = axios.create({
    // baseURL: 'http://web.imlab.com/'
    baseURL: 'http://140.119.164.214:408/'
});

setInterceptor(axiosInstance);

export const apiExecutor = apiFactory(axiosInstance);
